<template>
  <div>
    <h3 ref="intro">
      Ratios
    </h3>
    <p>
      In mathematics, a ratio is used to compare two objects or quantities &mdash; such as counts, sizes, or weights of objects. Suppose you're comparing two objects.
      Then, a ratio of <i>m:n</i> means that there are <i>m</i> counts of object 1 for every <i>n</i> counts of object 2.
      <br><br>
      Let's take an example. Imagine you are in a class of 40 students out of which 30 are boys and 10 are girls.
      Then the boys to girls ratio in the class would be 3:1 &mdash; basically telling us that there three times more boys in the class than girls.
      <br> <br>
      Let's take another example. Imagine you were given a bruit basket.
      You find that the basket contains 6 apples, 4 oranges, and 8 peaches. So, what is the ratio of apples to oranges in the basket?
      That answer is 3:2 &mdash; telling us that there are 3 apples for every 2 oranges in the basket.
    </p>
    <h3 ref="types">
      Notation to Express Ratios
    </h3>
    <ul class="a">
      <li>
        <h5> Using a Colon</h5> The most common way to express a ratio is using a colon.
        For example &mdash; in the first example above, the ratio of boys to girls, using a colon, can be expressed as 1:3
      </li>
      <li>
        <h5> As a Fraction </h5> A ratio can also be expressed as a fraction. For example &mdash; a ratio of 1:3 can also be expressed as 1/3.
      </li>
      <li>
        <h5> Using '<i> is to </i>' </h5> Sometimes ratios are also expressed in words using '<i>is to</i>'. For example, a ratio of 1:3 can also be expressed as '1 <i>is to</i> 3'.
      </li>
    </ul>
    <br>
    <h3 ref="pg">
      MagicGraph | Playing with Ratios
    </h3>
    <br>
    <p>
      You are given two weighing scales: a red scale and a blue scale. The red scale can only carry red bricks while the blue scale can only carry blue bricks.
      Each red block weighs 3 kg while each blue block weighs 4 kg. Your can add or remove bricks by tapping on the empty squares over each scale.
      The MagicGraph shows the ratio of red bricks to blue bricks as you add or remove bricks.
    </p>
    <br>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'Ratio',
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Ratios of Numbers ';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'Geometric Tools', img:'/assets/number-1.svg', action: () => this.goto('intro')},
      {title: 'Common Geometric Tools', img:'/assets/number-2.svg', action: () => this.goto('types')},
      {title: 'MagicGraph',img:'/assets/touch.svg', action: () => this.goto('pg')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Ratio of Numbers',
          titleTemplate: '%s - Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Learn interactively about Thales Theorem'}
                ]
        }
   },
}
</script>

<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
.icn{
color: var(--v-red-base);;
}
</style>
