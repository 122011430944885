import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeText,
    placeMiddleText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeCircle,
    placeAngle,
    placeDash,
    placeLabel,
    placeArc,
    placeLogo
} from '../edliy_utils-fractions';
const Boxes = {
    //JXG.Options.line.highlight=false;
    box1: function () {
    //Create the Board
    var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-6, 16, 14, -4],
        keepaspectratio: true, axis:false, ticks:{visible:true},
        grid:true, showCopyright:false, showNavigation:false,
        pan:{enabled:false}, zoom:{enabled:false}});

    brd1.suspendUpdate();
    // Layering
    brd1.options.axis.strokeWidth=0;
    brd1.options.layer['image']=15;
    brd1.options.layer['text']=16;
    brd1.options.layer['line']=16;
    brd1.options.layer['point']=17;
    brd1.options.layer['glider']=17;
    brd1.options.layer['angle']=18;
    brd1.options.board.minimizeReflow = 'none';
    brd1.options.point.showInfobox =false;
    brd1.options.line.highlight=false;
    brd1.options.image.highlight=false;
    brd1.options.text.highlight=false;
//    brd1.options.text.display='internal';
    //Make the board responsive
    makeResponsive(brd1);
    // Placing Logo
    placeLogo(brd1);
    //Placing Title
    placeTitle(brd1, 'Playing with Ratios', '(Click on a empty box to fill it with a brick)');
    placeMiddleText(brd1,  4, 13, 'Fill the empty racks such that the two scales weigh the same');
    placeText(brd1,  8.5, 9.5, 18, 'Ratio of RED bricks to BLUE bricks');
    placeText(brd1,  8.5, 7.5, 18, 'Each RED brick weighs 3 kg');
    placeText(brd1,  8.5, 6.5, 18, 'Each BLUE brick weighs 4 kg');
    placeText(brd1,  8.5, 5.5, 18, 'Ratio of RED scale to BLUE scale readings');
    //dragMe(brd1, 5, 5);
    //var stone=placeLine(brd1, [-2,0], [3,5], 10, 'grey');
  //  placeImage(brd1, '/assets/purpleSquare.svg',-8, 6, 2);
  //  placeMiddleText(brd1, -7, 7, '3 kg');
    //placeMiddleText(brd1, -7, 4, 'The racks on the left can only be filled with red squares');
  //  placeImage(brd1, '/assets/orangeSquare.svg', 6, 6, 2);
  //  placeMiddleText(brd1, 4, 4, 'The racks on the right can only be filled with blue squares');
  //  placeMiddleText(brd1, 7, 7, '4 kg');
    //Titles and subtitle
    //var title = brd1.create('text', [12, 22, '<b> Center of Mass <b>'],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(28*brd1.canvasWidth/500.)}},);
    //brd1.create('image', ['/assets/crosshair.svg', [7, 11], [1,1]], {visible: true, fixed:true});
    //var rectImg = brd1.create('image', ['/assets/orangeSquare.svg', [20,16], [2,2]], {visible: true, fixed:true});
    //var massKg = brd1.create('text', [21, 17, '1 kg'],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}},);
    //FIRST
    var width=2;
    var num_height=5;
    var num_width=2;
    var rectLeft=[];
    var rectRight=[];
    var crectLeft=[];
    var crectRight=[];
    var rectLeftVisibility=[];
    var rectRightVisibility=[];
    var crectLeftVisibility=[];
    var crectRightVisibility=[];
    var textLeft=[];
    var textRight=[];
    var cmx=0,cmy=0;

    for (let y=0; y< num_height; y++)
        {
            let v1 = true;
            let v2 = false;
            let rectyLeft = brd1.create('image', ['/assets/whiteSquare.svg', [-2*width, y*width], [2.01,2.01]], {visible: v1, opacity:0.25, fixed:true});
            let rectyRight = brd1.create('image', ['/assets/whiteSquare.svg', [width, y*width], [2.01,2.01]], {visible: v1, opacity:0.25, fixed:true});
            let crectyLeft = brd1.create('image', ['/assets/purpleSquare.svg', [-2*width, y*width], [2.01,2.01]], {visible: v2, fixed:true});
            let textyLeft = placeMiddleText(brd1, -2*width+0.5*width, y*width+0.5*width, '3 kg');
            textyLeft.setAttribute({visible:v2});
            let crectyRight = brd1.create('image', ['/assets/orangeSquare.svg', [width, y*width], [2.01,2.01]], {visible: v2, fixed:true});
            let textyRight = placeMiddleText(brd1, width+0.5*width, y*width+0.5*width, '4 kg');
            textyRight.setAttribute({visible:v2});
            rectyLeft.on('up', function(){changeLeftRectColorxy(y); });
            crectyLeft.on('up', function(){changeLeftRectColorxy(y); });
            rectyRight.on('up', function(){changeRightRectColorxy(y); });
            crectyRight.on('up', function(){changeRightRectColorxy(y); });
            rectLeft.push(rectyLeft);
            rectRight.push(rectyRight);
            crectLeft.push(crectyLeft);
            crectRight.push(crectyRight);
            textLeft.push(textyLeft);
            textRight.push(textyRight);
            rectLeftVisibility.push(v1);
            crectLeftVisibility.push(v2);
            rectRightVisibility.push(v1);
            crectRightVisibility.push(v2);
        }

    /*var j=0;
    var array =[7.5, 5.5, 6.5, 4.5];
    var darray=[8, 6, 7, 5];
    var shu =brd1.create('image', ['/assets/shuffle.svg', [-2, -3.83], [4.0, 4.0]], {visible:true, fixed:true, opacity:1});
    hoverMe(brd1, shu, 'Tap to Shuffle', 5, -15);
    shu.on('down', function(){if(j<3){return j=j+1}else{return j=0}});
    //brd1.create('image', ['/assets/triangle.svg', [()=>array[j], -1], [1.0, 1.0]], {visible:true, fixed:true, opacity:1});
    var perm =[];
    */
    var mass1=0, mass2=0, num1=0, num2=0;
    var massLeft=3;
    var massRight=4;
////////////////////////////////////////////////////////////////
//brd1.create('image', ['/assets/triangle.svg', [7, 12], [1.0, 1.0]], {visible:true, fixed:true, opacity:1});
////////////////////////////////////////////////////////////////////////////////////
    function changeLeftRectColorxy(y)
    {

        let current_visibility1 = rectLeftVisibility[y];
        let current_visibility2 = crectLeftVisibility[y];

        if (current_visibility1==true && current_visibility2==false)
        {
            current_visibility1=false;
            current_visibility2=true;

        }
        else{
            current_visibility1=true;
            current_visibility2=false;
        }

        rectLeft[y].setAttribute({visible: current_visibility1});
        crectLeft[y].setAttribute({visible: current_visibility2});
        textLeft[y].setAttribute({visible: current_visibility2});
        rectLeftVisibility[y]=current_visibility1;
        crectLeftVisibility[y]=current_visibility2;
        mass1 = 0;
        num1 = 0;
        for (let i=0;i< num_height;i++)
             {
                 if (crectLeftVisibility[i]==true)
                 {
                    mass1+=massLeft;
                    num1+=1;
                 }
             }
     }

     function changeRightRectColorxy(y)
     {

         let current_visibility1 = rectRightVisibility[y];
         let current_visibility2 = crectRightVisibility[y];

         if (current_visibility1==true && current_visibility2==false)
         {
             current_visibility1=false;
             current_visibility2=true;

         }
         else{
             current_visibility1=true;
             current_visibility2=false;
         }

         rectRight[y].setAttribute({visible: current_visibility1});
         crectRight[y].setAttribute({visible: current_visibility2});
         textRight[y].setAttribute({visible: current_visibility2});
         rectRightVisibility[y]=current_visibility1;
         crectRightVisibility[y]=current_visibility2;
         mass2 = 0;
         num2 = 0;
         for (let i=0;i< num_height;i++)
              {
                  if (crectRightVisibility[i]==true)
                  {
                     mass2+=massRight;
                     num2+=1;
                  }
              }
      }
    var txt1=placeMiddleText(brd1, -3, -2.3, function(){return '' + (mass1).toFixed(0) + ' kg'});
    txt1.setAttribute({color:'white'});
    var txt2=placeMiddleText(brd1, 3, -2.3, function(){return '' + (mass2).toFixed(0) + ' kg'});
    txt2.setAttribute({color:'white'});
    brd1.create('image', ['/assets/red-scale.svg', [-5, -3.83], [4.0, 4.0]], {visible:true, fixed:true, opacity:1});
    brd1.create('image', ['/assets/blue-scale.svg', [1, -3.83], [4.0, 4.0]], {visible:true, fixed:true, opacity:1});
    placeMiddleText(brd1,  8.5, 8.5, function(){return ''+ num1.valueOf() + ' : ' + num2.valueOf()});
    placeMiddleText(brd1,  8.5, 4.5, function(){return ''+ mass1.valueOf() + ' : ' + mass2.valueOf()});
   //brd1.create('text', [0, -2, function(){return '' + (mass1+mass2).toFixed(2)}],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}},);

  /*  var cgxtxt = brd1.create('text', [1.5, 18, 'X_c_m = '],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}},);
    var cgxtxt2 = brd1.create('text', [4, 18.5, ' &sum; m_i x_i '],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'bottom', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}},);
    brd1.create('segment',[[3., 18],[5., 18]],{strokeWidth:2, strokeColor:'black', fixed:true});
    var cgxtxt3 = brd1.create('text', [4, 17.5, '&sum;m_i'],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}},);

    var cgytxt = brd1.create('text', [1.5, 15, 'Y_c_m = '],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}},);
    var cgytxt2 = brd1.create('text', [4, 15.5, ' &sum; m_i y_i'],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'bottom', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}},);
    brd1.create('segment',[[3., 15],[5., 15]],{strokeWidth:2, strokeColor:'black', fixed:true});
    var cgytxt3 = brd1.create('text', [4, 14.5, '&sum;m_i'],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}},);

    var ok1 = brd1.create('image', ['/assets/check.svg', [10, 17.5], [1 , 1]], {visible: false, fixed: true});
    var ok2 = brd1.create('image', ['/assets/check.svg', [10, 14.5], [1 , 1]], {visible: false, fixed: true});
    var wrong1 = brd1.create('image', ['/assets/cross.svg', [10, 17.5], [1 , 1]], {visible: false, fixed: true});
    var wrong2 = brd1.create('image', ['/assets/cross.svg', [10, 14.5], [1 , 1]], {visible: false, fixed: true});

    var pointCM = brd1.create('image', ['/assets/crosshair.svg', [function(){return (masss*cmx0 + mass2*cmx)/(mass2+masss)- 0.5}  ,function(){return (masss*cmy0 + mass2*cmy)/(mass2+masss)- 0.5}], [1,1]], {visible: true, fixed:true});
    var cgx = brd1.create('text', [7, 18, function(){return ' = ' + (mass).toFixed(2)}],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}},);
    var cgy = brd1.create('text', [7, 15, function(){return ' = ' + ((masss*cmy0 + mass2*cmy)/(mass2+masss)).toFixed(2)}],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}},);
    brd1.create('text', [8.5, 11.5, function(){return '= Center of Mass =' + '('+ ((masss*cmx0 + mass2*cmx)/(mass2+masss)).toFixed(2) +','+ ((masss*cmy0 + mass2*cmy)/(mass2+masss)).toFixed(2) +')' }],{visible: true, fixed: true, anchorX: 'left', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd1.canvasWidth/800.)}},);
    brd1.create('image', ['/assets/check.svg', [12, 15.75], [2 , 2]], {visible: function(){return darray[j]==((masss*cmx0 + mass2*cmx)/(mass2+masss)).toFixed(2)}, fixed: true});
*/
    brd1.unsuspendUpdate();
                      },
              }
export default Boxes;
